<template>
  <div class="px-10">
    <div class="text-h5 primary--text mb-5">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ลูกค้าเพิ่มตัง
    </div>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>ชื่อลูกค้า</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="name"
          v-model="form.name"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>Username</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="username"
          v-model="form.username"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>ชื่อร้าน</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="branchs"
          v-model="form.branchs"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>Password</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="password"
          v-model="form.password"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>เบอร์โทร</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="phone_number"
          v-model="form.phone_number"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1"></v-col>
      <v-col cols="3" class="text-right py-10">
        <v-btn
          class="ml-5"
          color="primary"
          small
          outlined
          hide-details
          @click="savepassword()"
          :loading="loading"
          :disabled="loading"
          >บันทึก Password ใหม่</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>ชื่อออกใบเสร็จ</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="name_for_receipt"
          v-model="form.name_for_receipt"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>บิลที่ใช้ไปเดือนนี้</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="note"
          v-model="form.bill_count"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">เลขประจำตัวผู้เสียภาษี</v-col>
      <v-col cols="3">
        <v-text-field
          name="note"
          v-model="form.taxpayer_number"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <div>เปิดใช้งานหัก ณ ที่จ่าย 3%</div>
      </v-col>
      <v-col cols="3">
        <v-radio-group class="mt-0" row v-model="form.is_withholding">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <!-- <v-spacer /> -->
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">สาขา</v-col>
      <v-col cols="3">
        <v-text-field
          name="company_branch"
          v-model="form.company_branch"
          label=""
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1">
        <v-btn
          class="px-5"
          color="error"
          @click="removeCustomer()"
          :loading="loading"
          :disabled="true"
          >ลบข้อมูลลูกค้า</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">ที่อยู่</v-col>
      <v-col cols="3">
        <v-text-field
          name="address"
          outlined
          dense
          hide-details
          autocomplete="new-password"
          @click="
            (dialogAddressData = form.address),
              (dialogAddress = true),
              (dialogAddressKey = 'address')
          "
          :value="`${form.address.address}  ${form.address.sub_district} ${form.address.district} ${form.address.province} ${form.address.postal_code}`"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="1">หมายเหตุ</v-col>
      <v-col cols="3">
        <v-textarea
          name="note"
          v-model="form.note"
          label=""
          outlined
          dense
          chips
        />
      </v-col>
    </v-row>
    <!-- ตารางแพ็กเกจปัจจุบัน-->
    <v-toolbar dense flat class="mb-5">
      <div class="grey--text text-h5">แพ็กเกจปัจจุบัน</div>
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="form.active_package"
      :loading="loading"
      sort-by="code"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          form.active_package
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ item.category == 0 ? 'หลัก' : '' }}
        {{ item.category == 1 ? 'เสริม' : '' }}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{ item.product_type == 0 ? 'ครั้งเดียว' : '' }}
        {{ item.product_type == 1 ? 'รายเดือน' : '' }}
        {{ item.product_type == 2 ? 'รายปี' : '' }}
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{ item.start_date | moment($formatDate) }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | moment($formatDate) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item
                dense
                link
                @click="
                  (product_id = item._id),
                    (main_package = item.main_package),
                    (dialogUpdate = true)
                "
              >
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <br />
    <!-- ตารางแพ็กเกจที่เคยใช้ -->
    <v-toolbar dense flat class="mb-5">
      <div class="grey--text text-h5">แพ็กเกจที่เคยใช้</div>
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers2"
      :items="form.inactive_package"
      :loading="loading"
      sort-by="code"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          form.inactive_package
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ item.category == 0 ? 'หลัก' : '' }}
        {{ item.category == 1 ? 'เสริม' : '' }}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{ item.product_type == 0 ? 'ครั้งเดียว' : '' }}
        {{ item.product_type == 1 ? 'รายเดือน' : '' }}
        {{ item.product_type == 2 ? 'รายปี' : '' }}
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{ item.start_date | moment($formatDate) }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | moment($formatDate) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item
                dense
                link
                @click="
                  (product_id = item._id),
                    (main_package = item.main_package),
                    (dialogUpdate = true)
                "
              >
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
      <CustomerDetail
        :id="product_id"
        :main_package="main_package"
        @onClose="dialogUpdate = false"
        @onSave="getData(), (dialogUpdate = false)"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
import CustomerDetail from '@/views/backoffice/backoffice/customer/CustomerDetail.vue';
import AddressDetail from '@/components/Address/AddressDetail.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    AddressDetail,
    CustomerDetail,
  },
  validations: {
    form: {
      name: { required },
    },
  },
  data: () => ({
    loading: false,
    customer_id: '',
    product_id: '',
    main_package: false,

    form: {
      _id: '',
      company_branch: '',
      company_group_id: '',
      is_withholding: false,
      name: '',
      name_for_receipt: '',
      note: '',
      password: '',
      phone_number: '',
      taxpayer_number: '',
      user_name: '',
      active_package: [],
      inactive_package: [],
      branchs: [],
      address: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
    },
    headers: [
      {
        text: 'ลำดับ',
        align: 'start',
        sortable: false,
        value: 'index',
        filterable: false,
      },
      { text: 'แพ็กเกจ', value: 'name' },
      { text: 'หมวดหมู่', value: 'category', filterable: false },
      { text: 'รูปแบบ', value: 'product_type', filterable: false },
      { text: 'ราคาสุทธิ', value: 'price', filterable: false },
      { text: 'วันเริ่มต้น', value: 'start_date', filterable: false },
      { text: 'วันสิ้นสุด', value: 'end_date', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],
    headers2: [
      {
        text: 'ลำดับ',
        align: 'start',
        sortable: false,
        value: 'index',
        filterable: false,
      },
      { text: 'แพ็กเกจ', value: 'name' },
      { text: 'หมวดหมู่', value: 'category', filterable: false },
      { text: 'รูปแบบ', value: 'product_type', filterable: false },
      { text: 'ราคาสุทธิ', value: 'price', filterable: false },
      { text: 'วันเริ่มต้น', value: 'start_date', filterable: false },
      { text: 'วันสิ้นสุด', value: 'end_date', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],

    dialogUpdate: false,
    dialogData: null,
    loadingCar: true,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      building: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },
    list: {
      branchs: [],
      products: [],
      main_categories: [
        { category_id: 'PC1', name: 'พรบ.' },
        { category_id: 'PC2', name: 'ประกันภัย' },
        { category_id: 'PC3', name: 'ขนส่ง' },
        { category_id: 'PC4', name: 'ทั่วไป' },
      ],
    },
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
    },
  }),

  async created() {
    if (this.isUpdate) {
      await this.getData();
    }
  },

  methods: {
    async getData() {
      if (this.$route.params.id) {
        let body = {
          token: this.$jwt.sign(
            { customer_id: this.$route.params.id },
            this.$privateKey,
            { noTimestamp: true }
          ),
        };
        await this.$axios
          .post(`${this.$baseUrl}/backend/customer/get_by_id`, body)
          .then(async (res) => {
            this.form = { ...res.result };
            console.log(this.form);
            if (typeof res.result.address.address == 'object') {
              res.result.address.address = '';
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
    savepassword() {
      this.$v.form.$touch();
      this.loading = true;

      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'add_password';
            if (this.isUpdate) {
              subPath = 'edit_password';
              this.form = { customer_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/customer/changed_password`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate) this.$router.push({ name: 'product' });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          this.$v.form.$reset();
        }
      );
    },

    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            delete this.form.company_group_id;
            if (this.isUpdate) {
              this.form = { customer_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/customer/edit_customer`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.$router
                  .push({
                    name: 'backoffice-customer-update',
                    params: { id: res.result },
                  })
                  .catch(() => {});
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
        }
      );
    },
    close() {
      this.$router.push({ name: 'backoffice-customer' });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { logs_id: item._id, main_package: item.main_package },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/customer/delete_package`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    removeCustomer() {
      this.$alertConfirm({ title: `ยืนยันการลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let body = {
              token: this.$jwt.sign(
                { user_id: this.$route.params.id },
                this.$privateKey,
                {
                  noTimestamp: true,
                }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/customer/delete_customer`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.$router.push({ name: 'backoffice-customer' });
                //this.$emit('success');
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            this.loading = false;
          }
        }
      );
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
    category_name() {
      let filtered = this.list.main_categories.filter((el) => {
        return el.category_id == this.form.category_id;
      });
      return filtered.length ? filtered[0].name : '';
    },
  },
};
</script>
