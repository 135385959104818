<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >ตั้งค่าสิทธิ์กลุ่มผู้ใช้งาน</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col
          cols="11"
          offset="1"
          style="position: sticky; top: 0; z-index: 2; background-color: white"
        >
          <v-row>
            <v-col cols="2" class="text-h5 mt-2">ชื่อกลุ่ม</v-col>
            <v-col cols="4">
              <v-text-field
                label="ชื่อกลุ่ม"
                class="mt-2"
                name="form.group_name"
                v-model="form.group_name"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10">
          <v-card width="100%">
            <v-card-title
              class="primary white--text py-3"
              style="top: 72px;"
            >
              <v-col cols="4" class="text-center">รายการ</v-col>
              <v-col cols="8" class="text-center">ตัวเลือก</v-col>
            </v-card-title>
            <v-card-text
              class="py-5"
              style=""
            >
              <v-row v-for="(item, index) in items" :key="index">
                <v-col
                  cols="4"
                  class="checklist pl-5"
                  :class="{ 'pl-15 grey--text': !item.isMainList }"
                  >{{ item.name }}</v-col
                >
                <v-col
                  cols="8"
                  class="d-flex"
                  v-if="item.display"
                >
                  <!-- Dynamically Render Radio Options -->
                  <v-radio-group
                    v-model="form.permission[item.keyName]"
                    @change="onChange(item.keyName, form.permission[item.keyName])"
                    class="d-flex mt-0"
                    row 
                  >
                    <v-radio
                      v-for="choice in getChoices(item)"
                      :key="choice.value"
                      :value="choice.value"
                      :label="choice.label"
                      class="radio-spacing"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึก</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  watch: {
    id: function (newVal, oldVal) {
      this.getData(newVal);
    },
  },
  mounted() {
    this.getData(this.id);
  },
  data() {
    return {
      loading: false,
      form: {
        user_group_id: 0,
        group_name: '',
        permission: {
          // sell_document: 0,
          // car_data: 0,
          // customer_data: 0,
          job_follow_up: 0,
          delete_document: 0,
          show_phone_number: 0,
          // product_data: 0,
          report_quotations: 0,
          report_unpaid_balance: 0,
          report_total_sales: 0,
          report_product_sales: 0,
          report_staff_sales: 0,
          report_profit_loss: 0,
          report_outgoing_cost: 0,
          report_commission: 0,
          report_quotation: 0,
          report_invoice: 0,
          expenses: 0,
          expense_withholding: 0,
          expense_category: 0,
          expense_car_tax: 0,
          expense_insurance: 0,
          salary: 0,
          setting: 0,
          package: 0,
          credit_document: 0,
          dedit_document: 0,
          date_of_issue: 0,
          payment_date: 0,
          reset_document: 0,
        },
      },
      defaultChoices: [
      { value: 0, label: 'ไม่แสดง' },
      { value: 1, label: 'เห็นของทุกคน' },
      { value: 2, label: 'เห็นเฉพาะกลุ่มที่ผู้ใช้อยู่' },
      ],
      items: [
        // { keyName: "sell_document", name: "เอกสารขาย", isMainList: true, display:true },
        // { keyName: "car_data", name: "ข้อมูลรถ", isMainList: true, display:true },
        // { keyName: "customer_data", name: "ข้อมูลลูกค้า", isMainList: true, display:true },
        {
          keyName: '',
          name: 'เอกสาร',
          isMainList: true,
          display: false,
        },
        {
          keyName: 'credit_document',
          name: 'ใบเพิ่มหนี้',
          isMainList: false,
          display: true,
          choices: [
            { value: 1, label: 'เห็นใบเพิ่มหนี้ + เก็บเงินเพิ่มได้' },
            { value: 0, label: 'เห็นใบเพิ่มหนี้ + เก็บเงินเพิ่มไม่ได้' },
            { value: 2, label: 'ไม่เห็นใบเพิ่มหนี้ + เก็บเงินเพิ่มไม่ได้' },
          ],
        },
        {
          keyName: 'debit_document',
          name: 'ใบลดหนี้',
          isMainList: false,
          display: true,
          choices: [
            { value: 1, label: 'เห็นใบลดหนี้ + กดคืนเงินได้' },
            { value: 0, label: 'เห็นใบลดหนี้ + กดคืนเงินไม่ได้' },
            { value: 2, label: 'ไม่เห็นใบลดหนี้ + กดคืนเงินไม่ได้' },
          ],
        },
        {
          keyName: 'job_follow_up',
          name: 'เมนูติดตามงานทั้งหมด',
          isMainList: true,
          display: true,
        },
        // { keyName: "product_data", name: "สินค้า", isMainList: true, display:true },

        { keyName: '', name: 'รายงาน', isMainList: true, display: false },
        {
          keyName: 'report_quotation',
          name: 'ใบเสนอราคา',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'report_invoice',
          name: 'ใบแจ้งหนี้ (ค้างชำระ)',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'report_total_sales',
          name: 'ยอดขายรวม',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'report_product_sales',
          name: 'ยอดขายสินค้า',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'report_staff_sales',
          name: 'ยอดขายพนักงาน',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'report_profit_loss',
          name: 'กำไร ขาดทุน',
          isMainList: false,
          display: true,
        },
        // {
        //   keyName: 'report_outgoing_cost',
        //   name: 'ค่าใช้จ่าย',
        //   isMainList: false,
        //   display: true,
        // },
        {
          keyName: 'report_commission',
          name: 'ค่าคอมพ.ร.บ.​ประกันภัย',
          isMainList: false,
          display: true,
        },

        { keyName: '', name: 'ค่าใช้จ่าย', isMainList: true, display: false },
        {
          keyName: 'expenses',
          name: 'รายจ่าย',
          isMainList: false,
          display: true,
        },
        // {
        //   keyName: 'expense_withholding',
        //   name: 'หัก ณ ที่จ่าย',
        //   isMainList: false,
        //   display: true,
        // },
        {
          keyName: 'expense_car_tax',
          name: 'รายจ่ายภาษีรถ',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'expense_insurance',
          name: 'รายจ่าย พ.ร.บ. / ประกัน',
          isMainList: false,
          display: true,
        },
        {
          keyName: 'expense_category',
          name: 'หมวดหมู่รายจ่าย',
          isMainList: false,
          display: true,
        },

        // {
        //   keyName: 'salary',
        //   name: 'เงินเดือน',
        //   isMainList: true,
        //   display: true,
        // },
        {
          keyName: 'setting',
          name: 'ตั้งค่า',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่แสดง' },
            { value: 1, label: 'แสดง' },
          ],
        },
        {
          keyName: 'package',
          name: 'แพ็คเกจ',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่แสดง' },
            { value: 1, label: 'แสดง' },
          ],
        },
        {
          keyName: 'show_phone_number',
          name: 'แสดงเบอร์โทรลูกค้า',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่แสดง' },
            { value: 1, label: 'แสดง' },
          ],
        },
        {
          keyName: 'date_of_issue',
          name: 'แก้ไขวันที่สร้างเอกสาร',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่ได้' },
            { value: 1, label: 'แก้ไขได้' },
          ],
        },
        {
          keyName: 'payment_date',
          name: 'แก้ไขวันที่เก็บเงิน',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่ได้' },
            { value: 1, label: 'แก้ไขได้' },
          ],
        },
        {
          keyName: 'reset_document',
          name: 'รีเซ็ตเอกสาร',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ไม่ได้' },
            { value: 1, label: 'รีเซ็ตได้' },
          ],
        },
        {
          keyName: 'delete_document',
          name: 'ลบเอกสาร ',
          isMainList: true,
          display: true,
          choices: [
            { value: 0, label: 'ลบไม่ได้' },
            { value: 1, label: 'ลบได้' },
          ],
        },
      ],
    };
  },
  methods: {
    getChoices(item) {
      return item.choices || this.defaultChoices;
    },
    async getData(id) {
      if (id) {
        let body = {
          token: this.$jwt.sign({ user_group_id: id }, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/user_group/get_by_id`, body)
          .then(async (res) => {
            this.form = { ...res.result };
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        this.form = {
          user_group_id: 0,
          group_name: '',
          permission: {
            job_follow_up: 0,
            delete_document: 0,
            show_phone_number: 0,
            //report_quotations: 0,
            report_unpaid_balance: 0,
            report_total_sales: 0,
            report_product_sales: 0,
            report_staff_sales: 0,
            report_profit_loss: 0,
            report_outgoing_cost: 0,
            report_commission: 0,
            report_quotation: 0,
            report_invoice: 0,
            expenses: 0,
            expense_withholding: 0,
            expense_category: 0,
            expense_car_tax: 0,
            expense_insurance: 0,
            salary: 0,
            setting: 0,
            package: 0,
            credit_document: 0,
            dedit_document: 0,
            date_of_issue: 0,
            payment_date: 0,
            reset_document: 0,
          },
        };
      }
      this.loading = false;
    },
    save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'create_user_group';
            if (this.isUpdate) {
              subPath = 'edit_user_group';
              this.form = { user_group_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };

            await this.$axios
              .post(`${this.$baseUrl}/user_group/${subPath}`, body)
              .then((res) => {
                console.log(res);
                if (res.status == 'cannot edit owner permission') {
                  this.$alertServerError({ title: res.status });
                } else this.$alertSuccess({ title: res.status });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('save()');
            this.$emit('success');
            this.$emit('onClose');
          }
          this.loading = false;
        }
      );
    },
    close() {
      this.$emit('onClose');
    },
    onChange(keyName, value) {
      console.log(`Key: ${keyName}, Value: ${value}`);
    },
  },
  computed: {
    isUpdate() {
      return this.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.checklist {
  font-size: 20px;
}
.radio-spacing {
  margin-right: 30px; /* Adjust the space between radio buttons */
}
</style>
